
a {
  /* Anchors using a `click` event handler don't have an `href` attribute so aren't formatted
    as std links would, so we need to do this explicitly.
  */
  &:not([href]) {
    cursor: pointer;
  }
}

ion-label[position="stacked"].has-help-link {
  display: flex;
  justify-content: space-between;
  pointer-events: all !important;   /* !important needed to override Ionic */
}

.form-warning,
.form-validation,
wky-breach-warning {
  font-size: calc(0.75 * 16px);    /* Makes it the same size as a 'stacked' label */

  a {
    text-decoration-line: underline;
  }
  ion-item + &,
  .form-validation + & {
    padding-inline: calc(16px + var(--ion-safe-area-left, 0));
    margin-block-start: 0.5em;
  }
  ion-item[lines="none"] + & {
    margin-block-start: 0;
  }
}

.form-warning,
wky-breach-warning {
  color: var(--wky-color-text-warning);
}

.form-validation {
  color: var(--ion-color-danger-shade);   /* Use slightly darker version to help text stand out */
}

.form-button {
  text-align: center;

  a {
    display: block;
    margin-top: 0.5em;
  }
  ion-button {
    margin: var(--ion-padding) 0;
  }
}

.input-group {
  align-items: center;
  display: flex;
  width: 100%;
}

.input-switch {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;

  input[type="checkbox"] {
    cursor: pointer;
    height: 100%;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    width: 100%;
  }
}

.order-item-value {
  ion-label {
    flex: initial;
  }

  ion-label:first-child,
  ion-reorder + ion-label {
    max-width: unset;
  }

  ion-label + ion-label,
  ion-label + ion-input {
    text-align: end;
  }
}
