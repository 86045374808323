.firebase-emulator-warning {
  /* Hide as it overlays the tabs/FABs at the bottom of the screen*/
  display: none;
}

ion-content.fill::part(scroll) {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

ion-content.centred::part(scroll) {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

wky-template-selector,
wky-template-colour {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

wky-order-print-page.ion-page {   /* Class needed for extra specificity */
  overflow: auto;
}

wky-order-view {
  overflow: hidden;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: var(--template-view-margin);   /* Note `--template-view-margin` is defined in OrderViewComponent */
}

.colour-spot-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding-bottom: 0;
  padding-top: 0;
}

.colour-spot {
  height: 30px;
  width: 30px;

  div {
    border: transparent 1px solid;
    display: inline-block;
    height: 100%;
    width: 50%;

    &:first-child {
      border-bottom-left-radius: 999px;
      border-top-left-radius: 999px;
      border-right: none;
    }
    &:last-child {
      border-bottom-right-radius: 999px;
      border-top-right-radius: 999px;
      border-left: none;
    }
  }
}

[slot="empty-state"],
.empty-state {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  padding: 15%;
}

.filter-bar {
  background-color: rgb(var(--wky-color-filterbar-bg-rgb));
  display: flex;
  position: relative;

  label,
  div {
    align-items: center;
    display: inline-flex;
    padding-inline-start: var(--ion-padding);
    position: relative;
  }
  div {
    flex-grow: 1;
    overflow-x: auto;
  }

  &:after,
  label:after {
    content: "";
    height: 100%;
    position: absolute;
    width: var(--ion-padding);
  }
  &:after {
    background: linear-gradient(90deg, rgba(var(--wky-color-filterbar-bg-rgb), 0) 0%,
                                       rgba(var(--wky-color-filterbar-bg-rgb), 1) 50%,
                                       rgba(var(--wky-color-filterbar-bg-rgb), 1) 100%);
    right: 0;
  }
  label:after {
    background: linear-gradient(90deg, rgba(var(--wky-color-filterbar-bg-rgb), 1) 0%,
                                       rgba(var(--wky-color-filterbar-bg-rgb), 1) 50%,
                                       rgba(var(--wky-color-filterbar-bg-rgb), 0) 100%);
    right: calc(-1 * var(--ion-padding));
    top: 0;
    z-index: 1;
  }

  ion-chip {
    background-color: var(--ion-color-secondary-contrast);
    margin-block-start: calc(var(--ion-padding) * 0.5);
    margin-block-end: calc(var(--ion-padding) * 0.5);
    overflow: initial;

    &:not(.chip-outline) {
      background-color: var(--ion-color-secondary);
      border: 1px solid transparent;  /* Needed so chips don't 'move' when toggling selection */
      color: var(--ion-color-secondary-contrast);
    }
    &:first-child {
      margin-inline-start: 0;
    }
    &:last-child:after {  /* Adds a spacer so the last chip can be scrolled past the filterbar's background gradient */
      content: "";
      height: 100%;
      position: absolute;
      right: calc(-1 * var(--ion-padding));
      width: var(--ion-padding);
    }
  }
}

wky-notification {
  div.content {
    /* This styling is targetting the HTML content dynamically added to the NotificationComponent.
      Defining this in notification.component.scss won't work as the compiler will scope the
      selectors to the component, but the dynamic HTML won't include the scope class so won't
      be targetted correctly.  Defining this globally gets around this.
    */
    & > :first-child {
      margin-block-start: 0;
      margin-top: 0;
    }
    & > :last-child {
      margin-block-end: 0;
      margin-bottom: 0;
    }
  }
}
