input[type=number] {
  /* Remove spinner on Firefox (also duplicated in _wky_ionic-overrides) */
  -moz-appearance: textfield;

  /* Remove spinner on WebKit desktop browsers */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
