.label-stacked {
  /* Stacked labels in MD are scaled down by 0.75, causing the width to be reduced and making it impossible to
    right-align anything on the same line.  This increases the label container back to full width (100% / 0.75).
  */
  &.sc-ion-label-md-h {
    max-width: 133.333%;
    width: 133.333%;
  }
}

ion-label.has-skeleton-text {
  width: 100%
}

ion-button {
  &.button-round {
    --padding-start: var(--button-round-padding-horizontal);
    --padding-end: var(--button-round-padding-horizontal);

    &.button-has-icon-only {
      --padding-start: var(--button-icon-padding-horizontal);
      --padding-end: var(--button-icon-padding-horizontal);
    }
  }

  &.flatten {
    --transition: 0.25s linear all;

    &.button-disabled {
      --box-shadow: none;
      --opacity: 1;
    }
  }
}

/* By default, ionic sets -moz-appearance to 'none' which causes number inputs to display a
  spinner on desktop Firefox.  This override removes that (so it matches the display on mobiles).
*/
input[type=number].native-input {
  -moz-appearance: textfield;
}

ion-segment-button {
  &.has-badge {
    &::part(native) {
      flex-direction: row;
    }

    ion-badge {
      margin-left: 5px;
    }
  }
}

ion-tab-button {
  & > fa-icon {
    font-size: 1.7em;
  }
}

ion-badge + ion-badge {
  margin-inline-start: 1em;
}

/* Ionic removes the top padding on ion-card-contents directly following an ion-card-header,
  which is fine if both are white.  But if the header is coloured, it looks wrong (see
  https://github.com/ionic-team/ionic-framework/issues/24808).
*/
ion-card-header[color] + ion-card-content {
  padding-top: 1em;
}
