$icon-bullet-gap:         0.5em;
$distance-to-icon-centre: 0.5em;
$progress-line-width:     3px;

ul.icon-bullets {
  list-style: none;

  li {
    margin-block-end: 0.5em;
    text-indent: calc((var(--font-awesome-fixed-width) + #{$icon-bullet-gap}) * -1);
  }

  fa-icon {
    margin-inline-end: $icon-bullet-gap;
  }
}

.progress-icons {
  list-style: none;
  padding-inline-start: 1em;

  li {
    background-image: linear-gradient(var(--wky-color-progress-line), var(--wky-color-progress-line));
    background-repeat: no-repeat;
    background-size: $progress-line-width 100%;
    padding-block-end: 10px;
    padding-inline-start: calc(((var(--font-awesome-fixed-width) + #{$progress-line-width}) * 0.5) + #{$icon-bullet-gap});
    text-indent: calc((var(--font-awesome-fixed-width) + #{$icon-bullet-gap}) * -1);

    &:first-child {
      background-image: linear-gradient(transparent 0, transparent #{$distance-to-icon-centre}, var(--wky-color-progress-line) #{$distance-to-icon-centre});
    }
    &:last-child {
      background-image: linear-gradient(var(--wky-color-progress-line) 0, var(--wky-color-progress-line) #{$distance-to-icon-centre}, transparent #{$distance-to-icon-centre});
    }
  }
  fa-icon,
  fa-duotone-icon {
    margin-inline-end: $icon-bullet-gap;
  }
}
