/* TODO: TASK - remove this file when we're able to lazy-load the theme stylesheets
  These overrides are specific to the HTML content generated for the notes, etc.  Because this
  HTML is injected into the templates, any new elements - headings, paragraphs and so on, are not
  suffixed with the code that encapsulates them, whereas the styles in the theme stylesheet are.
  This results in the HTML content not adopting the styling.  To get around this, the styles must
  be defined outside the theme stylesheets so that they're not suffixed either.
*/
wky-order-template.deco {
  .order-notes {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-transform: uppercase;
    }
  }
  .small-print {
    p:first-child {
      margin-top: 0;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
}
