:root {
// TODO: should this just be the secondary (or even tertiary) colour instead??
  --wky-color-background:            #d0edfa;// #fdddcd;
  --wky-color-background-rgb:        208, 237, 250;    /* RGB of --wky-color-background */
  --wky-color-filterbar-bg-rgb:      var(--wky-color-background-rgb);
  --wky-color-orderstatus-bg:        var(--wky-color-background);
  --wky-color-progress-line:         var(--ion-color-medium);
  --wky-color-badge-border-contrast: #ffffff;
  --wky-color-mediocre:              #ffd06b;    /* Between warning and success */
  --wky-color-notbad:                #bddb76;    /* Between warning and success */
  --wky-color-text-warning:          #e46c18;    /* Because --ion-color-warning-shade doesn't have enough contrast */

  /* Values defined by Ionic but not exposed in a usable manner */
  --ion-background-color:                 #ffffff;
  --ion-text-color:                       #000000;
  --ion-slotted-margin-inline-ios:        10px;
  --ion-slotted-label-margin-ios:         10px 8px 10px 0;
  --ion-slotted-label-margin-md :         11px 0 10px;
  --ion-slotted-end-label-margin-left-md: 32px;
  --ion-detail-icon-font-size:            20px;

  --ion-padding: 16px;  // Ionic's default if padding is left as zero
  --wky-padding-start: 16px;  // Should ideally come from --ion-padding-start, but that isn't available

  --button-round-padding-horizontal: 1.2em;   // Default padding is a bit large
// TODO: TASK - this value was found by trial and error and really needs to be calculated from ionic's variables to be consistent
  --button-icon-padding-horizontal: 9.25px;   // Amend padding to make icon-only buttons properly round
  --template-view-width: 100%;
  --font-awesome-fixed-width: 1.25em;

  ion-alert.is-wide,
  ion-popover.is-wide {
    --width: 400px;
    --max-width: 85%;
  }
}
