/* HACK ALERT!  Ideally, we'd reference Ionic's styling to ensure that this matches the
  framework, but for now, it's simpler to hardcode things (at least until v6 is released
  with updated styling that will probably need a rethink).
*/
$border-radius-ios:     13px;
$border-radius-md:       4px;

div.color-picker {  // Including tag to make more specific than 'official' styles (since those will be loaded after these)
  border-color: var(--ion-color-medium);

  html.md & {
    border-radius: $border-radius-md;
  }
  html.ios & {
    border-radius: $border-radius-ios;
  }
  html.md & .saturation-lightness {
    border-top-left-radius: $border-radius-md - 1px;
    border-top-right-radius: $border-radius-md - 1px;
  }
  html.ios & .saturation-lightness {
    border-top-left-radius: $border-radius-ios - 1px;
    border-top-right-radius: $border-radius-ios - 1px;
  }
  .hue {
    height: 48px;
    margin-bottom: 0;
    margin-top: -16px;

    .cursor {
      height: 48px;
      border-radius: 8px;
    }
  }
  .button-area {   // Needed for buttons to increase specifity
    display: flex;
    flex-direction: row;
    width: 100%;

    html.md & {
      flex-wrap: wrap-reverse;
      justify-content: flex-end;
      padding: 8px;
    }
    html.ios & {
      margin-inline-end: -0.5px;
      padding: 0;
    }
    button {
      background-color: transparent;
      border: none;
      color: var(--ion-color-primary);
      line-height: 20px;
      overflow: hidden;
      touch-action: manipulation;
      user-select: none;
      -webkit-font-smoothing: antialiased;

      html.md & {
        font-size: 14px;
        font-weight: 500;
        margin: 0 8px 0 0;
        padding: 10px;
        text-align: end;
        text-transform: uppercase;
      }
      html.ios & {
        border-right: rgba(0, 0, 0, 0.2) solid 0.5px;
        border-top: rgba(0, 0, 0, 0.2) solid 0.5px;
        flex: 1 1 auto;
        font-size: 17px;
        height: 44px;
        margin: 0;
        min-width: 50%;
        padding: 1px 6px;
        text-align: center;

        &:first-child {
          border-bottom-left-radius: $border-radius-ios - 1px;
        }
        &:last-child {
          border-bottom-left-radius: $border-radius-ios - 1px;
          border-right: none;
          font-weight: 700;
        }
      }
    }
  }
}
